<template>
    <div id="pdf-print-ready">
        <v-toolbar color="primary" dark>
            <v-toolbar-title>
                <v-icon large left>mdi-home</v-icon>
                {{ $t("employment-reference-form.title") }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <LocaleSwitcher />
        </v-toolbar>

        <v-card-text v-if="is_complete">
            <v-alert text>{{
                $t("employment-reference-form.reference_complete")
            }}</v-alert>
        </v-card-text>

        <div v-else>
            <v-card-text>
                <v-row dense>
                    <v-col>
                        <p class="subtitle-1">{{ $t("common.to") }}: {{ contact_name }}</p>
                    </v-col>
                </v-row>
                <v-row dense>
                    <v-col>
                        <p class="subtitle-1">
                            {{ $t("employment-reference-form.contact_msg") }}
                        </p>
                    </v-col>
                </v-row>
                <v-row dense>
                    <v-col>
                        <p class="subtitle-1 font-weight-bold">
                            {{ applicant_name }}
                        </p>
                    </v-col>
                </v-row>
                  <v-row dense>
                    <v-col>
                        <p class="subtitle-1 font-weight-bold">
                            {{$t("employment-reference-form.dob")}}: {{ applicant_birthdate }}
                            <br />
                            {{ $t("employment-reference-form.start_date") }}: {{ start_date }}
                            <br />
                            {{ $t("employment-reference-form.end_date") }}: {{ end_date }}
                            <br />
                            {{ $t("employment-reference-form.monthly_income") }}: {{ "$" + monthly_income }}
                             <br />
                            {{ $t("employment-reference-form.position") }}: {{ applicant_position }}
                        </p>
                    </v-col>
                </v-row>
            </v-card-text>

            <hr />

            <v-card-text>
                <v-row dense>
                    <v-col>
                        <p class="subtitle-1 font-weight-bold">
                            {{ $t("employment-reference-form.answer_all_questions") }}
                        </p>
                    </v-col>
                </v-row>

                <v-form ref="form" v-model="valid" lazy-validation>
                    <v-row dense>
                        <v-col cols="8" md="12">
                            <v-radio-group
                                row
                                v-model="form.employment_type"
                                :rules="radioButtonsRules"
                            >
                                <v-radio
                                    value="fullTime"
                                    :label="
                                        $t(
                                            'employment-reference-form.employment_type_values.fullTime'
                                        )
                                    "
                                ></v-radio>
                                <v-radio
                                    value="partTime"
                                    :label="
                                        $t(
                                            'employment-reference-form.employment_type_values.partTime'
                                        )
                                    "
                                ></v-radio>
                                <v-radio
                                    value="seePayStub"
                                    :label="
                                        $t(
                                            'employment-reference-form.employment_type_values.seePayStub'
                                        )
                                    "
                                ></v-radio>
                            </v-radio-group>
                        </v-col>
                    </v-row>

                    <v-row dense>
                        <v-col cols="12" md="7">
                            <v-row dense>
                                <v-col cols="12" md="6">
                                    <v-menu
                                        ref="menu1"
                                        v-model="menu1"
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        max-width="290px"
                                        min-width="auto"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                outlined
                                                autocomplete="off"
                                                :rules="textFieldRules"
                                                :label="$t('employment-reference-form.start_date')"
                                                v-model="form.start_date"
                                                prepend-icon="mdi-calendar"
                                                v-bind="attrs"
                                                @blur="date1 = parseDate(form.start_date)"
                                                v-on="on"
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            v-model="date1"
                                            no-title
                                            @input="menu1 = false"
                                        ></v-date-picker>
                                    </v-menu>
                                </v-col>

                                <v-col cols="12" md="6">
                                    <v-menu
                                        ref="menu2"
                                        v-model="menu2"
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        max-width="290px"
                                        min-width="auto"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                outlined
                                                autocomplete="off"
                                                v-model="form.end_date"
                                                :label="$t('employment-reference-form.end_date')"
                                                prepend-icon="mdi-calendar"
                                                v-bind="attrs"
                                                @blur="date2 = parseDate(form.end_date)"
                                                v-on="on"
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            v-model="date2"
                                            no-title
                                            @input="menu2 = false"
                                        ></v-date-picker>
                                    </v-menu>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>

                    <hr />
                    <br />

                    <v-row dense>
                        <v-col cols="12" md="3">
                            <v-subheader class="subtitle-1">{{
                                $t("employment-reference-form.position")
                            }}</v-subheader>
                        </v-col>
                        <v-col cols="11" md="4">
                            <v-text-field
                                outlined
                                v-model="form.applicant_position"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row dense>
                        <v-col cols="12" md="3">
                            <v-subheader class="subtitle-1">{{
                                $t("employment-reference-form.employment_type") + " *"
                            }}</v-subheader>
                        </v-col>
                        <v-col cols="11" md="4">
                            <v-radio-group
                                row
                                v-model="form.compensation_type"
                                :rules="radioButtonsRules"
                            >
                                <v-radio
                                    value="salary"
                                    :label="
                                        $t('employment-reference-form.compensation_type.salary')
                                    "
                                ></v-radio>
                                <v-radio
                                    value="hourly"
                                    :label="
                                        $t('employment-reference-form.compensation_type.hourly')
                                    "
                                ></v-radio>
                            </v-radio-group>
                        </v-col>
                    </v-row>

                    <v-row dense v-if="form.compensation_type == 'salary'">
                        <v-col cols="12" md="3">
                            <v-subheader class="subtitle-1">{{
                                $t("employment-reference-form.monthly_salary") + " *"
                            }}</v-subheader>
                        </v-col>
                        <v-col cols="11" md="4">
                            <v-currency-field
                                prefix="$"
                                outlined
                                type="number"
                                :rules="currencyFieldRules"
                                v-model="form.monthly_salary"
                            ></v-currency-field>
                        </v-col>
                    </v-row>

                    <v-row dense v-if="form.compensation_type == 'hourly'">
                        <v-col cols="12" md="3">
                            <v-subheader class="subtitle-1">{{
                                $t("employment-reference-form.hourly_wage") + " *"
                            }}</v-subheader>
                        </v-col>
                        <v-col cols="11" md="4">
                            <v-currency-field
                                prefix="$"
                                outlined
                                type="number"
                                :rules="currencyFieldRules"
                                v-model="form.hourly_wage"
                            ></v-currency-field>
                        </v-col>
                    </v-row>

                    <v-row dense v-if="form.compensation_type == 'hourly'">
                        <v-col cols="12" md="3">
                            <v-subheader class="subtitle-1">{{
                                $t("employment-reference-form.hours_worked_per_week") + " *"
                            }}</v-subheader>
                        </v-col>
                        <v-col cols="12" md="4">
                            <v-text-field
                                outlined
                                type="number"
                                :rules="textFieldRules"
                                v-model="form.hours_worked_per_week"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row dense>
                        <v-col cols="12">
                            <v-textarea
                                outlined
                                class="ml-lg-4"
                                v-model="form.comments"
                                :label="$t('common.additional_comments')"
                                rows="3"
                            ></v-textarea>
                        </v-col>
                    </v-row>

                    <hr />
                    <br />

                    <v-row dense>
                        <v-col cols="12" lg="2">
                            <v-subheader class="subtitle-1">{{
                                $t("employment-reference-form.your_name") + " *"
                            }}</v-subheader>
                        </v-col>
                        <v-col cols="12" lg="4">
                            <v-text-field
                                outlined
                                :rules="textFieldRules"
                                v-model="form.contact_name"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" lg="2">
                            <v-subheader class="subtitle-1">{{
                                $t("employment-reference-form.position") + " *"
                            }}</v-subheader>
                        </v-col>
                        <v-col cols="12" lg="4">
                            <v-text-field
                                outlined
                                :rules="textFieldRules"
                                v-model="form.contact_position"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <div v-if="!valid" class="red--text mr-4">
                            {{ $t("common.fix_form_errors") }}
                        </div>
                        <v-btn color="primary" @click="submitForm" :disabled="!valid">
                            <v-icon class="mr-2">mdi-check-circle</v-icon
                            >{{ $t("common.submit") }}
                        </v-btn>
                    </v-card-actions>
                </v-form>
            </v-card-text>
        </div>
    </div>
</template>

<script>
export default {
    name: "employment-reference-form",
    components: {
        LocaleSwitcher: () => import("@components/global/utils/LocaleSwitcher"),
    },
    props: {
        reference: {
            type: Object,
            required: true,
        },
        url: {
            type: String,
            required: true,
        },
    },
    data(vr) {
        return {
            // API data
            is_complete: this.reference.is_complete,
            applicant_name: this.reference.applicant.name,
            applicant_position: this.reference.reference.address.position,
            start_date: this.reference.reference.address.start_date,
            end_date: this.reference.reference.address.end_date,
            monthly_income: this.reference.reference.address.monthly_income,
            contact_name: this.reference.reference.contact.name,
            applicant_birthdate: this.reference.applicant.birthdate,

            // component logic
            valid: true,
            menu1: false,
            menu2: false,
            date1: null,
            date2: null,

            //form rules
            radioButtonsRules: [(v) => v !== null || vr.$t("common.select_option_error")],
            textFieldRules: [(v) => !!v || vr.$t("common.required_error")],
            currencyFieldRules: [(v) => v !== "0.00" || vr.$t("common.required_error"), (v) => !!v || vr.$t("common.required_error")],

            form: {
                employment_type: null,
                start_date: null,
                end_date: null,
                applicant_position: null,
                compensation_type: null,
                monthly_salary: null,
                hourly_wage: null,
                hours_worked_per_week: null,
                comments: null,
                contact_name: null,
                contact_position: null,
            },
        };
    },
    computed: {
        computedDateFormatted1() {
            return this.formatDate(this.date1);
        },
        computedDateFormatted2() {
            return this.formatDate(this.date2);
        },
    },
    watch: {
        date1() {
            this.form.start_date = this.formatDate(this.date1);
        },
        date2() {
            this.form.end_date = this.formatDate(this.date2);
        },
    },
    methods: {
        formatDate(date) {
            if (!date) return null;

            const [year, month, day] = date.split("-");
            return `${month}/${day}/${year}`;
        },
        parseDate(date) {
            if (!date) return null;

            const [month, day, year] = date.split("/");
            return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
        },
        submitForm() {
            const isValid = this.$refs.form.validate();
            if (isValid) {
                this.$emit("submit", { data: this.form, url: this.url });
            }
        },
    },
};
</script>
