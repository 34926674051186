var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "pdf-print-ready" } },
    [
      _c(
        "v-toolbar",
        { attrs: { color: "primary", dark: "" } },
        [
          _c(
            "v-toolbar-title",
            [
              _c("v-icon", { attrs: { large: "", left: "" } }, [
                _vm._v("mdi-home")
              ]),
              _vm._v(
                " " + _vm._s(_vm.$t("employment-reference-form.title")) + " "
              )
            ],
            1
          ),
          _c("v-spacer"),
          _c("LocaleSwitcher")
        ],
        1
      ),
      _vm.is_complete
        ? _c(
            "v-card-text",
            [
              _c("v-alert", { attrs: { text: "" } }, [
                _vm._v(
                  _vm._s(_vm.$t("employment-reference-form.reference_complete"))
                )
              ])
            ],
            1
          )
        : _c(
            "div",
            [
              _c(
                "v-card-text",
                [
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c("v-col", [
                        _c("p", { staticClass: "subtitle-1" }, [
                          _vm._v(
                            _vm._s(_vm.$t("common.to")) +
                              ": " +
                              _vm._s(_vm.contact_name)
                          )
                        ])
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c("v-col", [
                        _c("p", { staticClass: "subtitle-1" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("employment-reference-form.contact_msg")
                              ) +
                              " "
                          )
                        ])
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c("v-col", [
                        _c(
                          "p",
                          { staticClass: "subtitle-1 font-weight-bold" },
                          [_vm._v(" " + _vm._s(_vm.applicant_name) + " ")]
                        )
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c("v-col", [
                        _c(
                          "p",
                          { staticClass: "subtitle-1 font-weight-bold" },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("employment-reference-form.dob")
                                ) +
                                ": " +
                                _vm._s(_vm.applicant_birthdate) +
                                " "
                            ),
                            _c("br"),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("employment-reference-form.start_date")
                                ) +
                                ": " +
                                _vm._s(_vm.start_date) +
                                " "
                            ),
                            _c("br"),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("employment-reference-form.end_date")
                                ) +
                                ": " +
                                _vm._s(_vm.end_date) +
                                " "
                            ),
                            _c("br"),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "employment-reference-form.monthly_income"
                                  )
                                ) +
                                ": " +
                                _vm._s("$" + _vm.monthly_income) +
                                " "
                            ),
                            _c("br"),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("employment-reference-form.position")
                                ) +
                                ": " +
                                _vm._s(_vm.applicant_position) +
                                " "
                            )
                          ]
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c("hr"),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c("v-col", [
                        _c(
                          "p",
                          { staticClass: "subtitle-1 font-weight-bold" },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "employment-reference-form.answer_all_questions"
                                  )
                                ) +
                                " "
                            )
                          ]
                        )
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-form",
                    {
                      ref: "form",
                      attrs: { "lazy-validation": "" },
                      model: {
                        value: _vm.valid,
                        callback: function($$v) {
                          _vm.valid = $$v
                        },
                        expression: "valid"
                      }
                    },
                    [
                      _c(
                        "v-row",
                        { attrs: { dense: "" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "8", md: "12" } },
                            [
                              _c(
                                "v-radio-group",
                                {
                                  attrs: {
                                    row: "",
                                    rules: _vm.radioButtonsRules
                                  },
                                  model: {
                                    value: _vm.form.employment_type,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "employment_type", $$v)
                                    },
                                    expression: "form.employment_type"
                                  }
                                },
                                [
                                  _c("v-radio", {
                                    attrs: {
                                      value: "fullTime",
                                      label: _vm.$t(
                                        "employment-reference-form.employment_type_values.fullTime"
                                      )
                                    }
                                  }),
                                  _c("v-radio", {
                                    attrs: {
                                      value: "partTime",
                                      label: _vm.$t(
                                        "employment-reference-form.employment_type_values.partTime"
                                      )
                                    }
                                  }),
                                  _c("v-radio", {
                                    attrs: {
                                      value: "seePayStub",
                                      label: _vm.$t(
                                        "employment-reference-form.employment_type_values.seePayStub"
                                      )
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { attrs: { dense: "" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "7" } },
                            [
                              _c(
                                "v-row",
                                { attrs: { dense: "" } },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "6" } },
                                    [
                                      _c(
                                        "v-menu",
                                        {
                                          ref: "menu1",
                                          attrs: {
                                            "close-on-content-click": false,
                                            transition: "scale-transition",
                                            "offset-y": "",
                                            "max-width": "290px",
                                            "min-width": "auto"
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "activator",
                                              fn: function(ref) {
                                                var on = ref.on
                                                var attrs = ref.attrs
                                                return [
                                                  _c(
                                                    "v-text-field",
                                                    _vm._g(
                                                      _vm._b(
                                                        {
                                                          attrs: {
                                                            outlined: "",
                                                            autocomplete: "off",
                                                            rules:
                                                              _vm.textFieldRules,
                                                            label: _vm.$t(
                                                              "employment-reference-form.start_date"
                                                            ),
                                                            "prepend-icon":
                                                              "mdi-calendar"
                                                          },
                                                          on: {
                                                            blur: function(
                                                              $event
                                                            ) {
                                                              _vm.date1 = _vm.parseDate(
                                                                _vm.form
                                                                  .start_date
                                                              )
                                                            }
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.form
                                                                .start_date,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.form,
                                                                "start_date",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "form.start_date"
                                                          }
                                                        },
                                                        "v-text-field",
                                                        attrs,
                                                        false
                                                      ),
                                                      on
                                                    )
                                                  )
                                                ]
                                              }
                                            }
                                          ]),
                                          model: {
                                            value: _vm.menu1,
                                            callback: function($$v) {
                                              _vm.menu1 = $$v
                                            },
                                            expression: "menu1"
                                          }
                                        },
                                        [
                                          _c("v-date-picker", {
                                            attrs: { "no-title": "" },
                                            on: {
                                              input: function($event) {
                                                _vm.menu1 = false
                                              }
                                            },
                                            model: {
                                              value: _vm.date1,
                                              callback: function($$v) {
                                                _vm.date1 = $$v
                                              },
                                              expression: "date1"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "6" } },
                                    [
                                      _c(
                                        "v-menu",
                                        {
                                          ref: "menu2",
                                          attrs: {
                                            "close-on-content-click": false,
                                            transition: "scale-transition",
                                            "offset-y": "",
                                            "max-width": "290px",
                                            "min-width": "auto"
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "activator",
                                              fn: function(ref) {
                                                var on = ref.on
                                                var attrs = ref.attrs
                                                return [
                                                  _c(
                                                    "v-text-field",
                                                    _vm._g(
                                                      _vm._b(
                                                        {
                                                          attrs: {
                                                            outlined: "",
                                                            autocomplete: "off",
                                                            label: _vm.$t(
                                                              "employment-reference-form.end_date"
                                                            ),
                                                            "prepend-icon":
                                                              "mdi-calendar"
                                                          },
                                                          on: {
                                                            blur: function(
                                                              $event
                                                            ) {
                                                              _vm.date2 = _vm.parseDate(
                                                                _vm.form
                                                                  .end_date
                                                              )
                                                            }
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.form.end_date,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.form,
                                                                "end_date",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "form.end_date"
                                                          }
                                                        },
                                                        "v-text-field",
                                                        attrs,
                                                        false
                                                      ),
                                                      on
                                                    )
                                                  )
                                                ]
                                              }
                                            }
                                          ]),
                                          model: {
                                            value: _vm.menu2,
                                            callback: function($$v) {
                                              _vm.menu2 = $$v
                                            },
                                            expression: "menu2"
                                          }
                                        },
                                        [
                                          _c("v-date-picker", {
                                            attrs: { "no-title": "" },
                                            on: {
                                              input: function($event) {
                                                _vm.menu2 = false
                                              }
                                            },
                                            model: {
                                              value: _vm.date2,
                                              callback: function($$v) {
                                                _vm.date2 = $$v
                                              },
                                              expression: "date2"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("hr"),
                      _c("br"),
                      _c(
                        "v-row",
                        { attrs: { dense: "" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "3" } },
                            [
                              _c("v-subheader", { staticClass: "subtitle-1" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("employment-reference-form.position")
                                  )
                                )
                              ])
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "11", md: "4" } },
                            [
                              _c("v-text-field", {
                                attrs: { outlined: "" },
                                model: {
                                  value: _vm.form.applicant_position,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "applicant_position",
                                      $$v
                                    )
                                  },
                                  expression: "form.applicant_position"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { attrs: { dense: "" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "3" } },
                            [
                              _c("v-subheader", { staticClass: "subtitle-1" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "employment-reference-form.employment_type"
                                    ) + " *"
                                  )
                                )
                              ])
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "11", md: "4" } },
                            [
                              _c(
                                "v-radio-group",
                                {
                                  attrs: {
                                    row: "",
                                    rules: _vm.radioButtonsRules
                                  },
                                  model: {
                                    value: _vm.form.compensation_type,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "compensation_type",
                                        $$v
                                      )
                                    },
                                    expression: "form.compensation_type"
                                  }
                                },
                                [
                                  _c("v-radio", {
                                    attrs: {
                                      value: "salary",
                                      label: _vm.$t(
                                        "employment-reference-form.compensation_type.salary"
                                      )
                                    }
                                  }),
                                  _c("v-radio", {
                                    attrs: {
                                      value: "hourly",
                                      label: _vm.$t(
                                        "employment-reference-form.compensation_type.hourly"
                                      )
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm.form.compensation_type == "salary"
                        ? _c(
                            "v-row",
                            { attrs: { dense: "" } },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "3" } },
                                [
                                  _c(
                                    "v-subheader",
                                    { staticClass: "subtitle-1" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "employment-reference-form.monthly_salary"
                                          ) + " *"
                                        )
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "11", md: "4" } },
                                [
                                  _c("v-currency-field", {
                                    attrs: {
                                      prefix: "$",
                                      outlined: "",
                                      type: "number",
                                      rules: _vm.currencyFieldRules
                                    },
                                    model: {
                                      value: _vm.form.monthly_salary,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "monthly_salary",
                                          $$v
                                        )
                                      },
                                      expression: "form.monthly_salary"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.form.compensation_type == "hourly"
                        ? _c(
                            "v-row",
                            { attrs: { dense: "" } },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "3" } },
                                [
                                  _c(
                                    "v-subheader",
                                    { staticClass: "subtitle-1" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "employment-reference-form.hourly_wage"
                                          ) + " *"
                                        )
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "11", md: "4" } },
                                [
                                  _c("v-currency-field", {
                                    attrs: {
                                      prefix: "$",
                                      outlined: "",
                                      type: "number",
                                      rules: _vm.currencyFieldRules
                                    },
                                    model: {
                                      value: _vm.form.hourly_wage,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "hourly_wage", $$v)
                                      },
                                      expression: "form.hourly_wage"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.form.compensation_type == "hourly"
                        ? _c(
                            "v-row",
                            { attrs: { dense: "" } },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "3" } },
                                [
                                  _c(
                                    "v-subheader",
                                    { staticClass: "subtitle-1" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "employment-reference-form.hours_worked_per_week"
                                          ) + " *"
                                        )
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "4" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      outlined: "",
                                      type: "number",
                                      rules: _vm.textFieldRules
                                    },
                                    model: {
                                      value: _vm.form.hours_worked_per_week,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "hours_worked_per_week",
                                          $$v
                                        )
                                      },
                                      expression: "form.hours_worked_per_week"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "v-row",
                        { attrs: { dense: "" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c("v-textarea", {
                                staticClass: "ml-lg-4",
                                attrs: {
                                  outlined: "",
                                  label: _vm.$t("common.additional_comments"),
                                  rows: "3"
                                },
                                model: {
                                  value: _vm.form.comments,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "comments", $$v)
                                  },
                                  expression: "form.comments"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("hr"),
                      _c("br"),
                      _c(
                        "v-row",
                        { attrs: { dense: "" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", lg: "2" } },
                            [
                              _c("v-subheader", { staticClass: "subtitle-1" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "employment-reference-form.your_name"
                                    ) + " *"
                                  )
                                )
                              ])
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", lg: "4" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  outlined: "",
                                  rules: _vm.textFieldRules
                                },
                                model: {
                                  value: _vm.form.contact_name,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "contact_name", $$v)
                                  },
                                  expression: "form.contact_name"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", lg: "2" } },
                            [
                              _c("v-subheader", { staticClass: "subtitle-1" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "employment-reference-form.position"
                                    ) + " *"
                                  )
                                )
                              ])
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", lg: "4" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  outlined: "",
                                  rules: _vm.textFieldRules
                                },
                                model: {
                                  value: _vm.form.contact_position,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "contact_position", $$v)
                                  },
                                  expression: "form.contact_position"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-card-actions",
                        [
                          _c("v-spacer"),
                          !_vm.valid
                            ? _c("div", { staticClass: "red--text mr-4" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("common.fix_form_errors")) +
                                    " "
                                )
                              ])
                            : _vm._e(),
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "primary", disabled: !_vm.valid },
                              on: { click: _vm.submitForm }
                            },
                            [
                              _c("v-icon", { staticClass: "mr-2" }, [
                                _vm._v("mdi-check-circle")
                              ]),
                              _vm._v(_vm._s(_vm.$t("common.submit")) + " ")
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }